@charset "UTF-8";

@font-face {
  font-family: "anita-icon";
  src: url("../fonts/fonticon.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "anita-icon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-password_ic:before {
  content: "\e924";
}
.icon-login_ic:before {
  content: "\e925";
}
.icon-down_ic:before {
  content: "\e920";
}
.icon-left_ic:before {
  content: "\e921";
}
.icon-right_ic:before {
  content: "\e922";
}
.icon-up_ic:before {
  content: "\e923";
}
.icon-notif_ic:before {
  content: "\e91f";
}
.icon-add_bookmark_ic:before {
  content: "\e900";
}
.icon-attach_ic:before {
  content: "\e901";
}
.icon-biaya_lain_ic:before {
  content: "\e902";
}
.icon-blank_doc_ic:before {
  content: "\e903";
}
.icon-buku_besar_ic:before {
  content: "\e904";
}
.icon-cetaik_ic:before {
  content: "\e905";
}
.icon-check_ic:before {
  content: "\e906";
}
.icon-close_ic:before {
  content: "\e907";
}
.icon-date_ic:before {
  content: "\e908";
}
.icon-doc_ic:before {
  content: "\e909";
}
.icon-filter_ic:before {
  content: "\e90a";
}
.icon-hapus_ic:before {
  content: "\e90b";
}
.icon-home_ic:before {
  content: "\e90c";
}
.icon-import_ic:before {
  content: "\e90d";
}
.icon-info_ic:before {
  content: "\e90e";
}
.icon-input_ic:before {
  content: "\e90f";
}
.icon-kas_bank_ic:before {
  content: "\e910";
}
.icon-laporan_ic:before {
  content: "\e911";
}
.icon-list_ic:before {
  content: "\e912";
}
.icon-menu_ic:before {
  content: "\e913";
}
.icon-more_ic:before {
  content: "\e914";
}
.icon-pajak_ic:before {
  content: "\e915";
}
.icon-pembelian_ic:before {
  content: "\e916";
}
.icon-pengatura_ic:before {
  content: "\e917";
}
.icon-pengaturan_ic:before {
  content: "\e918";
}
.icon-penjualan_ic:before {
  content: "\e919";
}
.icon-persedian_ic:before {
  content: "\e91a";
}
.icon-search_ic:before {
  content: "\e91b";
}
.icon-simpan_ic:before {
  content: "\e91c";
}
.icon-tandai_ic:before {
  content: "\e91d";
}
.icon-user_2_ic:before {
  content: "\e91e";
}
